import React from "react"
import * as styles from "styles/app-link/index.module.scss"
// markup
const AppLink: React.FC = () => {
    if (typeof navigator !== 'undefined') {
        const ua = navigator.userAgent;
        if (ua.search(/iPhone/) != -1 || ua.search(/iPad/) != -1 || ua.search(/iPod/) != -1) {
            location.href = 'https://apps.apple.com/jp/app/id1619758573?mt=8';
        } else if (ua.search(/Android/) != -1) {
            location.href = 'https://play.google.com/store/apps/details?id=jp.baluko.app';
        }
    }
    return (
        <div className={styles.appLink}>
            <div className={styles.appLink__inner}>
                <div className={styles.appLink__innerHeader}>
                    <img
                        width={250}
                        src="https://balukoapplp.blob.core.windows.net/images/icon/balukoapp.svg"
                        alt="アプリアイコン"
                    />
                </div>
                <div className={styles.appLink__innerBody}>
                    <p className={styles.appLink__note}>自動でApp Store / Google Play が起動しない場合は下のボタンをクリックしてください。</p>

                    <div className={styles.appLink__btnGroup}>
                        <a href="https://apps.apple.com/jp/app/id1619758573?mt=8">
                            <img
                                width={107}
                                src="https://balukoapplp.blob.core.windows.net/images/icon/app_store_applink.png"
                                alt="App Store"
                            />
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=jp.baluko.app">
                            <img
                                width={134}
                                className={styles.appLink__icon__right}
                                src="https://balukoapplp.blob.core.windows.net/images/icon/google_play_store_applink.png"
                                alt="google play store"
                            />
                        </a>
                    </div>
                </div>
                <p className={styles.appLink__info}>
                    Appleのロゴは、米国もしくはその他の国や地域におけるApple Inc.の商標です。App Storeは、Apple Inc.のサービスマークです。Google Play および Google Play ロゴは、Google LLC の商標です。
                </p>
            </div>
        </div>
    )
}

export default AppLink

